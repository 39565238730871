html {
  height: 100%;
  background: black;
  color: #888;
  overflow: hidden;
  user-select: none;
}
html body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: "Lato";
  font-family: "Quicksand", sans-serif;
}
html body #app {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
html body #app .main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
html body #app .main::before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: #FFF;
  opacity: 0;
  transition: opacity 3s;
}
html body #app .main .greeting {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  transform: translateY(-80%);
}
html body #app .main .greeting h2 {
  color: white;
  margin: 0;
  font-weight: 500;
  font-size: 25px;
}
html body #app .main .greeting .slogan {
  width: 18rem;
  aspect-ratio: 2937/200;
  background-image: url("slogan.a6fa6d4a.png");
  background-size: 100%;
  background-repeat: no-repeat;
  opacity: 0.5;
  margin-bottom: 0.5rem;
}
html body #app .main .greeting .surprise {
  position: relative;
  width: 30rem;
  height: 0rem;
  transition: margin-top 1s 1.5s, height 1s 1.5s;
}
html body #app .main .greeting .surprise .door-cover {
  position: absolute;
  z-index: 1;
  width: 6rem;
  height: 100%;
  top: 49%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  justify-content: space-between;
}
html body #app .main .greeting .surprise .door-cover::before, html body #app .main .greeting .surprise .door-cover::after {
  content: "";
  display: inline-block;
  width: 3rem;
  height: 105%;
  background-color: #000;
}
@keyframes door-show {
  50% {
    width: 10rem;
    height: 100%;
  }
  100% {
    width: 10rem;
    height: 0%;
  }
}
html body #app .main .greeting .surprise .door-area {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 90%;
  aspect-ratio: 5/7;
}
html body #app .main .greeting .surprise .door-area .door {
  position: absolute;
  height: 100%;
  width: 100%;
  border: 0rem solid #FFF;
  background-color: #4b4b4b;
  border-radius: 7px;
  transition: border-width 1s 2s;
  box-sizing: border-box;
}
html body #app .main .greeting .surprise .door-area .door .lights {
  opacity: 0;
  transition: opacity 0.5s 0.3s;
}
html body #app .main .greeting .surprise .door-area .door .lights .light {
  position: absolute;
  z-index: 0;
  aspect-ratio: 700/226;
  height: 3rem;
  background-image: url("light.ad4d8676.png");
  background-size: 100%;
  animation: lights infinite;
}
html body #app .main .greeting .surprise .door-area .door .lights .light-1 {
  top: 10%;
  left: -20%;
  transform: translateY(-50%) rotate(-30deg);
  animation-duration: 2s;
}
html body #app .main .greeting .surprise .door-area .door .lights .light-2 {
  top: 30%;
  left: -25%;
  transform: translateY(-50%) rotate(-15deg);
  animation-duration: 3s;
}
html body #app .main .greeting .surprise .door-area .door .lights .light-3 {
  top: 50%;
  left: -10%;
  transform: translateY(-50%) rotate(0deg);
  animation-duration: 2.3s;
}
html body #app .main .greeting .surprise .door-area .door .lights .light-4 {
  top: 70%;
  left: -25%;
  transform: translateY(-50%) rotate(15deg);
  animation-duration: 2.5s;
}
html body #app .main .greeting .surprise .door-area .door .lights .light-5 {
  top: 90%;
  left: -20%;
  transform: translateY(-50%) rotate(30deg);
  animation-duration: 2.7s;
}
@keyframes lights {
  0% {
    opacity: 0.5;
    margin-left: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.5;
    margin-left: 0;
  }
}
html body #app .main .greeting .surprise .door-area .door .panel {
  position: absolute;
  top: -3px;
  left: -3px;
  height: 100%;
  width: 100%;
  border: 0rem solid #FFF;
  background-color: black;
  border-radius: 7px;
  transition: border-width 1s 2s, transform 1s;
}
html body #app .main .greeting .surprise .door-area .door .panel::before {
  content: "";
  position: absolute;
  width: 15%;
  aspect-ratio: 1;
  background-color: #FFF;
  border-radius: 100%;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.5s 4s, transform 1s;
  opacity: 0;
}
html body #app .main .greeting .surprise .tip {
  position: absolute;
  z-index: -1;
  right: 24%;
  top: 45%;
  width: 3.5rem;
  aspect-ratio: 3/2;
  background-size: 100%;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 0.5s 4.5s;
  font-family: "Caveat", cursive;
}
html body #app .main .greeting .surprise .tip::before, html body #app .main .greeting .surprise .tip::after {
  text-align: left;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.03rem;
  position: absolute;
  color: #FFF;
  left: 4.5rem;
  top: 1.5rem;
  overflow: hidden;
  white-space: nowrap;
  opacity: 0;
}
html body #app .main .greeting .surprise .tip::before {
  content: "It's the door...";
}
html body #app .main .greeting .surprise .tip::after {
  content: "to our creations";
  left: 6rem;
  top: 2.7rem;
}
@media all and (max-width: 414px) {
  html body #app .main .greeting .surprise .tip {
    right: 30%;
    top: 40%;
    aspect-ratio: 3/2;
    width: 3rem;
  }
  html body #app .main .greeting .surprise .tip::before, html body #app .main .greeting .surprise .tip::after {
    font-size: 1.1rem;
    left: 0.5rem;
    top: 2.7rem;
  }
  html body #app .main .greeting .surprise .tip::after {
    top: 3.8rem;
    left: 1.3rem;
    padding-right: 0.1rem;
  }
}
html body #app .main .greeting .say-hi {
  margin-top: 40px;
  font-size: 1.15rem;
}
html body #app .main .greeting .say-hi a {
  color: #BBB;
  text-decoration: none;
  transition: color 200ms linear;
}
html body #app .main .greeting .say-hi a:hover {
  color: white;
  text-decoration: none;
}
html body #app .main .links {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 15%;
}
html body #app .main .links a {
  opacity: 0.5;
  margin-right: 1.75rem;
  transition: opacity 0.2s;
}
html body #app .main .links a:hover {
  opacity: 0.75;
}
html body #app .main .links a:last-child {
  margin-right: 0px;
}
html body #app .main .links a::after {
  content: "";
  display: block;
  width: 1.3rem;
  aspect-ratio: 1;
  background-size: contain;
  background-repeat: no-repeat;
}
html body #app .main .links a.facebook::after {
  background-image: url("facebook.dd9c828b.png");
}
html body #app .main .links a.instagram::after {
  background-image: url("instagram.54ec20fc.png");
}
html body #app .main .links a.linkedin::after {
  background-image: url("linkedin.7d29d318.png");
}
html body #app .main .links a.cakeresume::after {
  background-image: url("cakeresume.eb7e7d94.png");
}
html body #app .main .links .more-info {
  position: relative;
  width: 1.3rem;
  height: 1.3rem;
  opacity: 0.5;
}
html body #app .main .links .more-info:hover {
  opacity: 0.75;
  cursor: pointer;
}
html body #app .main .links .more-info .bar {
  position: absolute;
  width: 0.2rem;
  height: 1.3rem;
  border-radius: 1vw;
  background-color: #FFF;
  transition: height 0.3s, transform 0.5s 0.3s, left 0.5s 0.3s;
}
html body #app .main .links .more-info .bar:nth-child(1) {
  left: 0;
}
html body #app .main .links .more-info .bar:nth-child(2) {
  left: calc(50% - 0.1rem);
}
html body #app .main .links .more-info .bar:nth-child(3) {
  left: 1.15rem;
}
html body #app .main .links .more-info.unfold .bar:nth-child(1) {
  transform: rotate(45deg);
  left: 0.6rem;
}
html body #app .main .links .more-info.unfold .bar:nth-child(2) {
  height: 0rem;
}
html body #app .main .links .more-info.unfold .bar:nth-child(3) {
  transform: rotate(-45deg);
  left: 0.6rem;
}
html body #app .main .company {
  position: absolute;
  bottom: 20%;
  z-index: 2;
  color: #BBB;
  opacity: 0;
  font-size: 1rem;
  transition: bottom 0.5s 0.75s, opacity 0.5s 0.75s;
}
html body #app .main .company div:last-child {
  margin-top: 0.5rem;
}
@media all and (max-width: 414px) {
  html body #app .main .company {
    font-size: 0.85rem;
  }
  html body #app .main .company div:last-child {
    margin-top: 0.15rem;
  }
}
html body #app .main .company.show {
  bottom: 22%;
  opacity: 1;
}
html body #app .main .floating-fish {
  position: absolute;
  z-index: 0;
  height: 80vh;
  transition: transform 0.15s, margin-left 0s 2s;
  user-select: none;
  animation: floating-fish 120s linear infinite;
  left: 100%;
}
html body #app .main .floating-fish.poke {
  transform: scale(1.025);
}
html body #app .main .floating-fish .rotate {
  animation: rotate-fish 150s linear infinite;
  height: 100%;
}
html body #app .main .floating-fish .rotate .blow {
  height: 100%;
}
html body #app .main .floating-fish .rotate .blow.active-1 .dot-1 {
  opacity: 0.5;
}
html body #app .main .floating-fish .rotate .blow.active-2 .dot-1, html body #app .main .floating-fish .rotate .blow.active-2 .dot-2 {
  opacity: 0.5;
}
html body #app .main .floating-fish .rotate .blow.active-3 .dot-1, html body #app .main .floating-fish .rotate .blow.active-3 .dot-2, html body #app .main .floating-fish .rotate .blow.active-3 .dot-3 {
  opacity: 0.5;
}
html body #app .main .floating-fish .rotate .blow.active-4 .dot-1, html body #app .main .floating-fish .rotate .blow.active-4 .dot-2, html body #app .main .floating-fish .rotate .blow.active-4 .dot-3, html body #app .main .floating-fish .rotate .blow.active-4 .dot-4 {
  opacity: 0.5;
}
html body #app .main .floating-fish .rotate .blow.active-5 .dot-1, html body #app .main .floating-fish .rotate .blow.active-5 .dot-2, html body #app .main .floating-fish .rotate .blow.active-5 .dot-3, html body #app .main .floating-fish .rotate .blow.active-5 .dot-4, html body #app .main .floating-fish .rotate .blow.active-5 .dot-5 {
  opacity: 0.5;
}
html body #app .main .floating-fish .rotate .blow.active-6 .dot-1, html body #app .main .floating-fish .rotate .blow.active-6 .dot-2, html body #app .main .floating-fish .rotate .blow.active-6 .dot-3, html body #app .main .floating-fish .rotate .blow.active-6 .dot-4, html body #app .main .floating-fish .rotate .blow.active-6 .dot-5, html body #app .main .floating-fish .rotate .blow.active-6 .dot-6 {
  opacity: 0.5;
}
html body #app .main .floating-fish .rotate .blow .dot-1 {
  position: absolute;
  aspect-ratio: 1;
  border-radius: 100%;
  background-color: #FFF;
  opacity: 0.1;
  z-index: 1;
  transition: opacity 0.5s 0s linear;
  width: 6vh;
  bottom: 29.3%;
  left: 26%;
}
html body #app .main .floating-fish .rotate .blow .dot-2 {
  position: absolute;
  aspect-ratio: 1;
  border-radius: 100%;
  background-color: #FFF;
  opacity: 0.1;
  z-index: 1;
  transition: opacity 0.5s 0s linear;
  width: 3vh;
  bottom: 33.3%;
  left: 35.6%;
}
html body #app .main .floating-fish .rotate .blow .dot-3 {
  position: absolute;
  aspect-ratio: 1;
  border-radius: 100%;
  background-color: #FFF;
  opacity: 0.1;
  z-index: 1;
  transition: opacity 0.5s 0s linear;
  width: 8.5vh;
  bottom: 15%;
  left: 32.5%;
}
html body #app .main .floating-fish .rotate .blow .dot-4 {
  position: absolute;
  aspect-ratio: 1;
  border-radius: 100%;
  background-color: #FFF;
  opacity: 0.1;
  z-index: 1;
  transition: opacity 0.5s 0s linear;
  width: 5vh;
  bottom: 23%;
  left: 45%;
}
html body #app .main .floating-fish .rotate .blow .dot-5 {
  position: absolute;
  aspect-ratio: 1;
  border-radius: 100%;
  background-color: #FFF;
  opacity: 0.1;
  z-index: 1;
  transition: opacity 0.5s 0s linear;
  width: 6.8vh;
  bottom: 10.5%;
  left: 48%;
}
html body #app .main .floating-fish .rotate .blow .dot-6 {
  position: absolute;
  aspect-ratio: 1;
  border-radius: 100%;
  background-color: #FFF;
  opacity: 0.1;
  z-index: 1;
  transition: opacity 0.5s 0s linear;
  width: 4vh;
  bottom: 16.5%;
  left: 62%;
}
html body #app .main .floating-fish .rotate .blow .dot.active {
  opacity: 0.5;
}
html body #app .main .floating-fish .rotate .blow img {
  height: 100%;
  opacity: 0.1;
  transition: opacity 0.5s;
}
html body #app .main.show-JD .greeting .surprise {
  margin-top: 40px;
  height: 112px;
}
html body #app .main.show-JD .greeting .surprise .door-cover {
  animation: door-show 1s 2.5s linear forwards;
}
html body #app .main.show-JD .greeting .surprise .door-area .door {
  border-width: 3px;
  cursor: pointer;
}
html body #app .main.show-JD .greeting .surprise .door-area .door .panel {
  border-width: 3px;
}
html body #app .main.show-JD .greeting .surprise .door-area .door .panel::before {
  border-width: 0.15rem;
  opacity: 1;
}
html body #app .main.show-JD .greeting .surprise .tip {
  opacity: 1;
}
html body #app .main.show-JD .greeting .surprise .tip::before, html body #app .main.show-JD .greeting .surprise .tip::after {
  animation: tip-show 1s 5.5s linear forwards;
}
@keyframes tip-show {
  100% {
    opacity: 1;
  }
}
html body #app .main.show-JD .floating-fish {
  animation-play-state: paused;
}
html body #app .main.show-JD .floating-fish .rotate {
  animation-play-state: paused;
}
html body #app .main.show-JD .floating-fish .rotate .blow {
  animation: fish-blow 2s forwards;
}
html body #app .main.show-JD .floating-fish .rotate img {
  opacity: 0.5;
}
html body #app .main.hold-door .door-area .door:hover .lights {
  opacity: 1;
}
html body #app .main.hold-door .door-area .door:hover .panel {
  transform: perspective(400px) rotateY(-50deg) translateX(10px) translateZ(25px);
}
@media all and (max-width: 414px) {
  html body #app .main.hold-door .door-area .door:hover .lights {
    opacity: 0;
  }
  html body #app .main.hold-door .door-area .door:hover .panel {
    transform: unset;
  }
  html body #app .main.hold-door .door-area .door.open .lights {
    opacity: 1;
  }
  html body #app .main.hold-door .door-area .door.open .panel {
    transform: perspective(400px) rotateY(-50deg) translateX(10px) translateZ(25px);
  }
}
html body #app .main.hold-door .tip {
  background-image: url("arrow.24e16038.gif");
}
@media all and (max-width: 414px) {
  html body #app .main.hold-door .tip {
    background-image: url("arrow-down.891c545c.gif");
  }
}
html body #app .main.go-to-JD::before {
  animation: strong-light 1s;
}
@keyframes strong-light {
  0% {
    z-index: 1;
  }
  100% {
    opacity: 1;
    z-index: 1;
  }
}
@media all and (max-width: 414px) {
  html body #app .main.go-to-JD::before {
    animation: strong-light 0.3s 0.7s;
  }
}
@keyframes floating-fish {
  0% {
    left: 100%;
    bottom: 50%;
  }
  50% {
    left: -100vh;
    bottom: -100%;
  }
  51% {
    left: -100vh;
    bottom: 100%;
  }
  100% {
    left: 100%;
    bottom: -100%;
  }
}
@keyframes fish-blow {
  0% {
    opacity: 1;
  }
  99% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes rotate-fish {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes typing {
  0% {
    border-color: #BBB;
  }
  20% {
    border-color: #BBB;
  }
  30% {
    border-color: #000;
  }
  70% {
    border-color: #000;
  }
  80% {
    border-color: #BBB;
  }
  100% {
    border-color: #BBB;
  }
}
.test {
  position: absolute;
  z-index: 3;
  width: 500px;
  height: 750px;
  border: 1px solid #FFF;
}
/*# sourceMappingURL=entry.af875207.css.map */
