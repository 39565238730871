@mixin layout-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
@mixin dot($width, $bottom, $left) {
    position: absolute;
    aspect-ratio: 1;
    border-radius: 100%;
    background-color: #FFF;
    opacity: .1;
    z-index: 1;
    transition: opacity .5s 0s linear;

    width: $width;
    bottom: $bottom;
    left: $left;
}

@mixin mobile {
    @media all and (max-width: 414px) {
        @content;
    }
}

html {
    height: 100%;
    background: rgb(0, 0, 0);
    color: #888;
    overflow: hidden;
    user-select: none;

    body {
        @include layout-center;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        margin: 0;
        padding: 0;
        text-align: center;
        font-family: "Lato";
        font-family: 'Quicksand', sans-serif;

        #app {
            @include layout-center;
            width: 100%;
            height: 100%;

            .main {
                @include layout-center;
                width: 100%;
                height: 100%;
                overflow: hidden;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    width: 100%;
                    height: 100%;
                    background-color: #FFF;
                    opacity: 0;
                    transition: opacity 3s;
                }
    
                .greeting {
                    @include layout-center;
                    flex-direction: column;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-80%);
    
                    // h1 {
                    //     font-weight: 100;
                    //     margin: 0;
                    // }
                    h2 {
                        color: white;
                        margin: 0;
                        font-weight: 500;
                        font-size: 25px;
                    }
                    .slogan {
                        width: 18rem;
                        aspect-ratio: 2937/200;
                        // background-color: #888;
                        background-image: url('../images/slogan.png');
                        background-size: 100%;
                        background-repeat: no-repeat;
                        opacity: .5;
                        margin-bottom: 0.5rem;
                    }
                    
                    .surprise {
                        position: relative;
                        width: 30rem;
                        height: 0rem;
                        transition: margin-top 1s 1.5s, height 1s 1.5s;

                        .door-cover {
                            position: absolute;
                            z-index: 1;
                            width: 6rem;
                            height: 100%;
                            top: 49%;
                            left: 50%;
                            transform: translateX(-50%) translateY(-50%);

                            display: flex;
                            justify-content: space-between;

                            &::before, &::after {
                                content: '';
                                display: inline-block;
                                width: 3rem;
                                height: 105%;
                                background-color: #000;
                            }

                            @keyframes door-show {
                                50% {
                                    width: 10rem;
                                    height: 100%;
                                }
                                100% {
                                    width: 10rem;
                                    height: 0%;
                                }
                            }
                        }
                        .door-area {
                            // display: none;
                            position: absolute;
                            z-index: 0;
                            top: 50%;
                            left: 50%;
                            transform: translateX(-50%) translateY(-50%);

                            // height: 105px;
                            height: 90%;
                            aspect-ratio: 5/7;

                            .door {
                                position: absolute;
                                height: 100%;
                                width: 100%;
    
                                border: 0rem solid #FFF;
                                background-color: rgb(75, 75, 75);
                                border-radius: 7px;
                                
                                transition: border-width 1s 2s;
                                box-sizing: border-box;
    
                                .lights {
                                    opacity: 0;
                                    transition: opacity .5s .3s;
    
                                    .light {
                                        position: absolute;
                                        z-index: 0;
                                        aspect-ratio: 700/226;
                                        height: 3rem;
                                        background-image: url('../images/light.png');
                                        background-size: 100%;
                                        animation: lights infinite;
        
                                        &-1 {
                                            top: 10%;
                                            left: -20%;
                                            transform: translateY(-50%) rotate(-30deg);
                                            animation-duration: 2s;
                                        }
                                        &-2 {
                                            top: 30%;
                                            left: -25%;
                                            transform: translateY(-50%) rotate(-15deg);
                                            animation-duration: 3s;
                                        }
                                        &-3 {
                                            top: 50%;
                                            left: -10%;
                                            transform: translateY(-50%) rotate(0deg);
                                            animation-duration: 2.3s;
                                        }
                                        &-4 {
                                            top: 70%;
                                            left: -25%;
                                            transform: translateY(-50%) rotate(15deg);
                                            animation-duration: 2.5s;
                                        }
                                        &-5 {
                                            top: 90%;
                                            left: -20%;
                                            transform: translateY(-50%) rotate(30deg);
                                            animation-duration: 2.7s;
                                        }
        
                                        @keyframes lights {
                                            0% {
                                                opacity: .5;
                                                margin-left: 0;
                                            }
                                            50% {
                                                opacity: .2;
                                                // margin-left: 5px;
                                            }
                                            100% {
                                                opacity: .5;
                                                margin-left: 0;
                                            }
                                        }
                                    }
                                }
                                .panel {
                                    position: absolute;
                                    top: -3px;
                                    left: -3px;

                                    height: 100%;
                                    width: 100%;
    
                                    border: 0rem solid #FFF;
                                    background-color: rgb(0, 0, 0);
                                    border-radius: 7px;
        
                                    transition: border-width 1s 2s, transform 1s;
        
    
                                    // door handle //
                                    &::before {
                                        content: '';
                                        position: absolute;
                                        width: 15%;
                                        aspect-ratio: 1;
                                        // border: 0rem solid #FFF;
                                        background-color: #FFF;
                                        border-radius: 100%;
                                        right: 0.5rem;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        transition: opacity 0.5s 4s, transform 1s;
                                        opacity: 0;
                                    }
                                }
                            }
                        }
                        
                        .tip {
                            position: absolute;
                            z-index: -1;
                            right: 24%;
                            top: 45%;
                            width: 3.5rem;
                            aspect-ratio: 3/2;
                            background-size: 100%;
                            background-repeat: no-repeat;
                            opacity: 0;
                            transition: opacity 0.5s 4.5s;
                            font-family: 'Caveat', cursive;

                            &::before, &::after {
                                text-align: left;
                                font-size: 1.2rem;
                                font-weight: 600;
                                letter-spacing: 0.03rem;
                                position: absolute;
                                color: #FFF;
                                left: 4.5rem;
                                top: 1.5rem;
                                overflow: hidden;
                                white-space: nowrap;
                                opacity: 0;

                            }
                            &::before {
                                content: "It's the door...";
                            }
                            &::after {
                                content: "to our creations";
                                left: 6rem;
                                top: 2.7rem;
                            }
                        }

                        @include mobile {
                            .tip {
                                right: 30%;
                                top: 40%;
                                aspect-ratio: 3/2;
                                width: 3rem;
    
                                &::before, &::after {
                                    font-size: 1.1rem;
                                    left: 0.5rem;
                                    top: 2.7rem;
    
                                }
                                &::after {
                                    top: 3.8rem;
                                    left: 1.3rem;
                                    padding-right: 0.1rem;
                                }
                            }
                        }
                    }

                    .say-hi {
                        margin-top: 40px;
                        font-size: 1.15rem;
            
                        a {
                            color: #BBB;
                            text-decoration: none;
                            transition: color 200ms linear;
                            &:hover {
                                color: white;
                                text-decoration: none;
                            }
                        }
                    }
                }
                .links {
                    position: absolute;
                    @include layout-center;
                    // top: 70%;
                    bottom: 15%;

                    a {
                        opacity: .5;
                        margin-right: 1.75rem;

                        transition: opacity .2s;

                        &:hover {
                            opacity: .75;
                        }

                        &:last-child {
                            margin-right: 0px;
                        }

                        &::after {
                            content: '';
                            display: block;
                            width: 1.3rem;
                            aspect-ratio: 1;
                            background-size: contain;
                            background-repeat: no-repeat;
                        }
                        &.facebook::after {
                            background-image: url('../images/facebook.png');
                        }
                        &.instagram::after {
                            background-image: url('../images/instagram.png');
                        }
                        &.linkedin::after {
                            background-image: url('../images/linkedin.png');
                        }
                        &.cakeresume::after {
                            background-image: url('../images/cakeresume.png');
                        }
                    }
                    .more-info {
                        position: relative;
                        width: 1.3rem;
                        height: 1.3rem;
                        opacity: 0.5;

                        &:hover {
                            opacity: .75;
                            cursor: pointer;
                        }

                        .bar {
                            position: absolute;
                            width: 0.2rem;
                            height: 1.3rem;
                            border-radius: 1vw;
                            background-color: #FFF;

                            transition: height 0.3s, transform 0.5s 0.3s, left 0.5s 0.3s;

                            &:nth-child(1) {
                                left: 0;
                            }
                            &:nth-child(2) {
                                left: calc(50% - 0.1rem);
                            }
                            &:nth-child(3) {
                                left: 1.15rem;
                            }
                        }

                        &.unfold {
                            .bar {
                                &:nth-child(1) {
                                    transform: rotate(45deg);
                                    left: 0.6rem;
                                }
                                &:nth-child(2) {
                                    height: 0rem;
                                }
                                &:nth-child(3) {
                                    transform: rotate(-45deg);
                                    left: 0.6rem;
                                }
                            }
                        }
                    }
                }
                .company {
                    position: absolute;
                    bottom: 20%;
                    z-index: 2;

                    color: #BBB;
                    opacity: 0;
                    font-size: 1rem;
                    transition: bottom .5s .75s, opacity .5s .75s;

                    div:last-child {
                        margin-top: 0.5rem;
                    }
                    @include mobile {
                        font-size: 0.85rem;

                        div:last-child {
                            margin-top: 0.15rem; 
                        }
                    }

                    &.show {
                        bottom: 22%;
                        opacity: 1;
                    }
                }

                .floating-fish {
                    position: absolute;
                    z-index: 0;
                    height: 80vh;
                    transition: transform .15s, margin-left 0s 2s;
                    user-select: none;

                    animation: floating-fish 120s linear infinite;
                    // animation: floating-fish 10s linear infinite;
                    // left: 30%;
                    left: 100%;
                    

                    &.poke {
                        transform: scale(1.025);
                    }
                    .rotate {
                        animation: rotate-fish 150s linear infinite;
                        height: 100%;

                        .blow {
                            height: 100%;

                            &.active-1 {
                                .dot {
                                    &-1 {
                                        opacity: .5;
                                    }
                                }
                            }
                            &.active-2 {
                                .dot {
                                    &-1, &-2 {
                                        opacity: .5;
                                    }
                                }
                            }
                            &.active-3 {
                                .dot {
                                    &-1, &-2, &-3 {
                                        opacity: .5;
                                    }
                                }
                            }
                            &.active-4 {
                                .dot {
                                    &-1, &-2, &-3, &-4 {
                                        opacity: .5;
                                    }
                                }
                            }
                            &.active-5 {
                                .dot {
                                    &-1, &-2, &-3, &-4, &-5 {
                                        opacity: .5;
                                    }
                                }
                            }
                            &.active-6 {
                                .dot {
                                    &-1, &-2, &-3, &-4, &-5, &-6 {
                                        opacity: .5;
                                    }
                                }
                            }

                            .dot {
                                &-1 {
                                    @include dot(6vh, 29.3%, 26%)
                                }
                                &-2 {
                                    @include dot(3vh, 33.3%, 35.6%)
                                }
                                &-3 {
                                    @include dot(8.5vh, 15%, 32.5%)
                                }
                                &-4 {
                                    @include dot(5vh, 23%, 45%)
                                }
                                &-5 {
                                    @include dot(6.8vh, 10.5%, 48%)
                                }
                                &-6 {
                                    @include dot(4vh, 16.5%, 62%)
                                }
                                &.active {
                                    opacity: .5;
                                }
                            }
                            img {
                                height: 100%;
                                opacity: .1;
                                transition: opacity .5s;
                            }

                        }
                    }
                }

                &.show-JD {
                    .greeting {
                        .surprise {
                            margin-top: 40px;
                            height: 112px;

                            .door-cover {
                                animation: door-show 1s 2.5s linear forwards;
                            }
                            .door-area {
                                .door {
                                    border-width: 3px;
                                    cursor: pointer;
    
                                    .panel {
                                        border-width: 3px;
    
                                        &::before {
                                            border-width: 0.15rem;
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                            .tip {
                                opacity: 1;

                                &::before, &::after {
                                    animation: tip-show 1s 5.5s linear forwards;
                                }
                                @keyframes tip-show {
                                    100% {
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                    .floating-fish {
                        animation-play-state: paused;

                        .rotate {
                            animation-play-state: paused;

                            .blow {
                                animation: fish-blow 2s forwards;
                            }
                            img {
                                opacity: .5;
                            }
                        }
                    }
                }
                &.hold-door {
                    .door-area {
                        .door {
                            &:hover {
                                .lights {
                                    opacity: 1;
                                }
                                .panel {
                                    transform: perspective(400px) rotateY(-50deg) translateX(10px) translateZ(25px);
                                }
                            }
                            @include mobile {
                                &:hover {
                                    .lights {
                                        opacity: 0;
                                    }
                                    .panel {
                                        transform: unset;
                                    }
                                }
                                &.open {
                                    .lights {
                                        opacity: 1;
                                    }
                                    .panel {
                                        transform: perspective(400px) rotateY(-50deg) translateX(10px) translateZ(25px);
                                    }
                                }
                            }
                        }
                    }
                    .tip {
                        background-image: url('../images/arrow.gif');

                        @include mobile {
                            background-image: url('../images/arrow-down.gif');
                        }
                    }
                }
                &.go-to-JD {
                    &::before {
                        animation: strong-light 1s;
                        @keyframes strong-light {
                            0% {
                                z-index: 1;
                            }
                            100% {
                                opacity: 1;
                                z-index: 1;
                            }
                        }
                        @include mobile {
                            animation: strong-light 0.3s 0.7s;
                        }
                    }
                }
            }
        }
    }
}

@keyframes floating-fish {
    0% {
        left: 100%;
        bottom: 50%;
    }
    50% {
        left: -100vh;
        bottom: -100%;
    }
    51% {
        left: -100vh;
        bottom: 100%;
    }
    100% {
        left: 100%;
        bottom: -100%;
    }
}
@keyframes fish-blow {
    0% {
        opacity: 1;
    }
    99% {
        transform: scale(2);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
@keyframes rotate-fish {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes typing {
    0% {
        border-color: #BBB;
    }
    20% {
        border-color: #BBB;
    }
    30% {
        border-color: #000;
    }
    70% {
        border-color: #000;
    }
    80% {
        border-color: #BBB;
    }
    100% {
        border-color: #BBB;
    }
}



.test {
    position: absolute;
    z-index: 3;
    width: 500px;
    height: 750px;
    border: 1px solid #FFF;
}